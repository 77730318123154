<template>
  <div id="group-editor-pages">

    <ExpandableTable
      :children="pages_hierarchy"
      :fields="pages_fields"
      highlightable

      @content_link_clicked="content_link_clicked"
      @cell_value_changed="pages_checkbox_changed"
    />

  </div>
</template>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { getPageNames } from '@/core/services/pagenames';
import ExpandableTable from '@/view/components/tables/ExpandableTable.vue';

export default {
  name: 'group-editor-pages',
  components: {
    ExpandableTable
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'pages']),
    pages_fields() {
      return [
        { name: 'name', text: this.$t('ACCESS.PAGE.NAME'), cols: 6 },
        { name: 'has_access', text: this.$t('ACCESS.PAGE.ACCESS'), cols: 2, type: 'checkbox' },
      ];
    },
    pages_hierarchy() {

      const new_pages = [];

      // this.group.pages is the pages the group currently has access to

      // this.pages is the pages I can grant access to
      if (this.pages) {

        for (const page of this.pages) {
          const translated_name = this.translate_page_name(page.name);

          if (translated_name.indexOf('ml-') >= 0) {
            continue;
          }

          let found = this.group.pages.find(p => p.page_id === page.id) !== undefined;

          const new_page = {
            id:     page.id,
            name:   translated_name,
            has_access: found,
            children: []
          };

          new_pages.push(new_page);
        }

        new_pages.sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        });

        return new_pages;
      }

      return [];
    },
  },
  props: ['group'],
  emits: ['created', 'deleted'],
  async mounted() {
    this.pages = await this.get_pages();
  },
  methods: {

    translate_page_name(name) {

      const translation = this.$t(getPageNames()[name]);

      if (translation === '' || translation.indexOf('.') >= 0) {
        console.error('page translation not exists: ' + name);
        return name;
      }

      return translation;
    },

    async content_link_clicked(name, item, row_index, col_index) {
      console.log('content_link_clicked', name, item, row_index, col_index);
    },

    async pages_checkbox_changed(value) {
      console.log('pages_checkbox_changed', value);
      console.log('group', this.group);

      if (value.value) {
        await this.add_page(value.item.id, this.group.id);
      }
      else {
        await this.remove_page(value.item.id, this.group.id);
      }
    },

    async add_page(page_id, group_id) {
      try {
        const res = await axios.post(`/access/page/${page_id}/group/${group_id}`);

        if (res.status === 201) {
          this.$emit('created', group_id, page_id);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_ADD_PAGE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_ADD_PAGE'));
      }
    },

    async remove_page(page_id, group_id) {
      try {
        const res = await axios.delete(`/access/page/${page_id}/group/${group_id}`);

        if (res.status === 204) {
          this.$emit('deleted', group_id, page_id);
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_REMOVE_PAGE'));
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_REMOVE_PAGE'));
      }
    },

    async get_pages() {
      try {
        const res = await axios.get(`/access/pages`);

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ACCESS.GROUP.UNABLE_GET_PAGES'));
      }
      catch (err) {

      }
    },

  },
  data() {
    return {
      pages: []
    };
  },
  watch: {

  },

};
</script>
<style lang="scss" scoped>

:deep .v-treeview-node__root:hover {
  cursor: pointer;
}

.tree-panel {
  background-color: white;
}

:deep .v-simple-checkbox {
  float: left;
  width: 22px;
  margin-left: 8px;
}

:deep .v-input__slot label {
  font-size: 12px;
}

:deep .v-data-table__wrapper tr:first-child {
  height: 66px;
  background-color: rgb(239, 239, 239) !important;
}

</style>
